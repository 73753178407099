body {
	background-color: #ffffff;
	font-family: 'Handlee', cursive, "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 16px;
}

.container {
	margin: 20px auto;
}

.header {
	height: 100px;
}

.head-space {
	border-top: 1px solid #71141e;
}

.project {
	color: #5cb5c6;
	font-size: 30px;
	float: left;
	line-height: 30px;
}

.project span {
	color: #addae2;
	font-size: 20px;
}

.sliderimg{
	width: 100%;
	height: 100%;
	object-fit: cover
  }

.nav {
	background-color: white;
	font-size: 21px;
	color: #71141e;
	border-color: white;
}

.navbar {
	margin-bottom: 0px;
}

.navbar .nav-pills>li>a:hover {
	background-color: #ffffff;
	border-bottom: 3px solid #eea0a9;
}


.navbar .nav-pills li .active {
	background-color: #ffffff;
	border-bottom: 3px solid #eea0a9;
	font-weight: bold;
	text-transform: uppercase;
}

.nav-pills a {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	color: #000000;
	text-transform: uppercase;
	font-weight: bold;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	background-color: #ffffff;
}

.content {
	padding: 20px;
	border-left: 1px solid #EEEEEE;
	border-right: 1px solid #EEEEEE;
}

h1 {
	font-size: 32px;
	font-weight: bold;
	padding-bottom: 5px;
	margin-top: 0;
	margin-bottom: 20px;
	text-transform: uppercase;
	font-family: 'Handlee', Helvetica Neue, Helvetica, sans-serif !important;
}

.spacer20 {
	padding-bottom: 20px;
}

.spacer50 {
	padding-bottom: 50px;
}

.margin-top25 {
	margin-top: 25px;
}

.DTTT.btn-group {
	margin-bottom: 10px;
}

.w-100p {
	width: 100%;
}

.w-99p {
	width: 99%;
}

.w-98p {
	width: 98%;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pr-0 {
	padding-right: 0px;
}

.pt-5 {
	padding-top: 10px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-50 {
	padding-top: 50px;
}

.logo-1 {
	background-color: #ffffff;
	padding-left: 0px;
}

.logo-2 {
	text-align: center;
	padding-right: 0px;
	padding-left: 0px;
}

.galerie-h1 {
	padding-top: 5px;
	padding-bottom: 0px;
	margin-bottom: 5px;
}

.logo-sub {
	font-size: 24px;
	font-family: 'Handlee', Helvetica Neue, Helvetica, sans-serif;
	font-weight: bold;
	color: #71141e;
}

.gnd-18 {
	font-size: 18px;
	line-height: 18px;
	font-family: 'Handlee', Helvetica Neue, Helvetica, sans-serif;
	color: #71141e;
}

.gnd-22 {
	font-size: 22px;
	line-height: 22px;
	font-family: 'Handlee', Helvetica Neue, Helvetica, sans-serif;
	color: #71141e;
}

.gnd-24 {
	font-size: 24px;
	line-height: 24px;
	font-family: 'Handlee', Helvetica Neue, Helvetica, sans-serif;
	color: #71141e;
}

.gnd-22-c2 {
	font-size: 22px;
	line-height: 22px;
	font-family: 'Handlee', Helvetica Neue, Helvetica, sans-serif;
	color: #eea0a9;
}

.gnd-24-b {
	font-size: 24px;
	line-height: 24px;
	font-family: 'Handlee', Helvetica Neue, Helvetica, sans-serif;
	font-weight: bold;
	color: #71141e;
}

.bt-1p {
	border-top: 1px solid #71141e;
}

.bb-1p {
	border-bottom: 1px solid #71141e;
}

.a-blue {
	color: #337ab7;
	text-decoration: none;
}

.fot-1 {
	padding-top: 10px;
	text-align: left;
	padding-left: 0px;
}

.fot-2 {
	padding-top: 10px;
	text-align: left;
}

.image-4-2 {
	position: relative;
	display: block;
	width: 100%;
}

.image-picture {
	position: relative;
	display: none;
	top: 0;
	left: 0;
	opacity: 0;
	filter: alpha(opacity=0);
	width: 100%;
}

.loaderImage {
	position: absolute;
	width: 50px;
	top: 50%;
	left: 50%;
}

